<template>
  <v-card
    outlined
    :style="isClosed ? 'border: 1px red solid' : ''"
  >
    <v-card-title class="py-0">
      <v-row
        align="center"
        justify="space-between"
      >
        <v-col cols="auto">
          {{ weekday.name }}
        </v-col>
        <v-col
          v-if="!emptyIsDefaultOpen"
          cols="auto"
        >
          <v-checkbox
            :label="$t('closed')"
            v-model="isClosed"
            hide-details
            class="ma-0"
            :color="isClosed ? 'red' : ''"
            :disabled="openingRule === 'location_is_always_closed'"
          />
        </v-col>
        <v-col
          cols="auto"
          v-if="showSlotSwitch"
        >
          <v-switch
            v-if="userIsAdmin"
            :label="$t('slots')"
            v-model="showCustomSlotOptions"
            hide-details
            class="ma-0"
            disabled
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text
      class="pb-0 pt-2"
      v-if="openingHourByWeekdays.length < 1"
    >
      <div
        v-if="isClosed && openingRule === 'location_is_always_open' && value.length > 0"
        class="red--text"
      >
        {{ $t('onlineOrderingIsClosedOn') }} {{ $t( weekday.name + 'sLC') }}
      </div>
      <div
        v-if="isClosed && openingRule !== 'location_is_always_open'"
        class="red--text"
      >
        {{ $t('closedOn') }} {{ $t(weekday.name + 'sLC') }}
      </div>
      <div
        v-if="openingRule === 'location_is_always_open' && value.length < 1"
        class="green--text"
      >
        {{ $t('locationIsAlwaysOpen') }}!
      </div>
      <div
        v-if="emptyIsDefaultOpen"
        class="green--text"
      >
        {{ $t('categoryIsOpenOnThisDayByDefault') }}
      </div>
      <div>{{ $t('noOpeningHoursSetYet') }} {{ $t('startByClickInTheButtonBelow') }}</div>
    </v-card-text>
    <v-card-text
      class="py-0"
      v-else
    >
      <template v-for="(openingHour, index) in openingHourByWeekdays">
        <OpeningHour
          :value="openingHour"
          :show-custom-slot-options="showCustomSlotOptions"
          :key="weekday.day + '-' + index"
          :weekday="weekday.name"
          @confirmRemove="removeFromOpeningHours(openingHour)"
        />
        <v-row
          :key="'d-' + weekday.day + '-' + index"
          v-if="index < openingHourByWeekdays.length - 1"
          dense
          align="center"
          justify="center"
        >
          <v-col
            cols="auto"
            class="grow"
          >
            <v-divider />
          </v-col>
          <v-col cols="auto">
            <p
              v-if="index < openingHourByWeekdays.length - 1"
              class="mx-auto mt-1 mb-0 text-subtitle-1"
            >
              {{ $t('AND') }}
            </p>
          </v-col>
          <v-col
            cols="auto"
            class="grow"
          >
            <v-divider />
          </v-col>
        </v-row>
      </template>
    </v-card-text>
    <v-card-actions>
      <v-container fluid>
        <v-row
          justify="space-between"
          dense
        >
          <v-col
            cols="auto"
            v-if="weekday.day !== '1'"
            class="grow"
          >
            <v-btn
              block
              large
              text
              color="primary"
              @click="setSameAsPreviousWeekDay(weekday.day)"
              class="text-body-2 text-md-subtitle-1"
            >
              <v-icon left>
                mdi-content-copy
              </v-icon>{{ $t('sameAsPreviousWeekday') }}
            </v-btn>
          </v-col>
          <v-col
            cols="auto"
            class="grow"
          >
            <v-btn
              block
              large
              text
              color="primary"
              @click="addNewHourLine(weekday.day)"
              class="text-body-2 text-md-subtitle-1"
            >
              <v-icon left>
                mdi-plus
              </v-icon>{{ $t('addAnotherOpeningHour') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-actions>
  </v-card>
</template>

<script>
import OpeningHour from "@/components/openingHours/OpeningHour"

export default {
	name: "OpeningHoursWeekday",
	components: {
		OpeningHour
	},
	props: {
		weekday: {
      type: Object,
      default: () => {}
    },
		value: {
      type: Array,
      default: () => []
    },
		openingRule: {
      type: String,
      default: ''
    },
    emptyIsDefaultOpen: {
      type: Boolean,
      default: false
    }
	},
	data() {
		return {
			showCustomSlotOptions: false,
			tempOpeningHours: [],
			closed: false,
			showSlotSwitch: false
		}
	},
	computed: {
		isClosed: {
			get() {
				return ((this.openingHourByWeekdays.length < 1 || this.openingRule === 'location_is_always_closed') && !this.emptyIsDefaultOpen)
			},
			set(value) {
				if(value) {
					for(let i = 0; i < this.openingHourByWeekdays.length; i++) {
						let openingHour = this.openingHourByWeekdays[i]
						this.removeFromOpeningHours(openingHour)
					}
				} else {
					this.addNewHourLine(this.weekday.day)
				}
			}
		},
		userIsAdmin () {
			return !!(this.$store.state.user.email.includes('@salescloud.is'))
		},
		openingHourByWeekdays: function() {
			if(!this.value || !Array.isArray(this.value) || this.value.length < 1) {
				return []
			}
			return this.value.filter(weekday => weekday.day  === this.weekday.day)
		},
	},
	methods: {
		addNewHourLine(weekday) {
			this.$emit('addNewOpeningHour', {day: weekday, starthours: null, endhours: null})
			// if(!this.value || !Array.isArray(this.value) || this.value.length < 1) {
			// 	this.value = []
			// }
			// this.value.push({day: weekday, starthours: null, endhours: null})
		},
		setSameAsPreviousWeekDay(weekday) {
			this.$emit('sameAsPrevious', weekday)
		},
		removeFromOpeningHours(openingHour) {
			const index = this.value.findIndex(o => o.day === openingHour.day && o.starthours === openingHour.starthours && o.endhours === openingHour.endhours)
			this.value.splice(index, 1)
		}
	}
}
</script>

<style scoped>

</style>
