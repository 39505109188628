<template>
  <v-container
    fluid
    class="py-0"
  >
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <TimeSelection
          filled
          :label="$t('from')"
          hide-details
          v-model="value.starthours"
          @changeTimeValue="changeStartHours"
          allow-time-input
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <TimeSelection
          filled
          :label="$t('to')"
          hide-details
          v-model="value.endhours"
          @changeTimeValue="changeEndHours"
          allow-time-input
        />
      </v-col>
    </v-row>
    <v-row v-if="showCustomSlots">
      <v-col
        cols="12"
        sm="4"
      >
        <v-text-field
          v-model="value.maximumSalesPerSlot"
          @keyup="maxSalesToFloat"
          prepend-icon="mdi-cash-multiple"
          :suffix="$t('ISK')"
          filled
          dense
          :label="$t('maxSalesPerSlot')"
          hide-details
        />
      </v-col>
      <v-col
        cols="12"
        sm="4"
      >
        <v-select
          v-model="value.intervalBetweenSlotsInMillis"
          prepend-icon="mdi-clock-fast"
          :items="timeIntervalsInMillis"
          item-text="text"
          item-value="value"
          :label="$t('intervalBetweenSlots')"
          filled
          dense
          hide-details
        />
      </v-col>
      <v-col
        cols="12"
        sm="4"
      >
        <v-select
          v-model="value.pauseBetweenSlotsInMillis"
          prepend-icon="mdi-pause-octagon"
          :items="timeIntervalsInMillis"
          item-text="text"
          item-value="value"
          :label="$t('pauseBetweenSlots')"
          filled
          dense
          hide-details
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="value.comment"
          :label="$t('comment')"
          prepend-icon="mdi-message-reply-text"
          clearable
          filled
          hide-details
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        class="py-0 text-center text-md-end"
      >
        <v-btn
          text
          color="error"
          @click="confirm = true"
        >
          {{ $t('removeOpeningHour') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog
      v-model="confirm"
      v-if="confirm"
      absolute
      scrollable
      max-width="500"
    >
      <v-card>
        <v-card-title>
          {{ $t('confirmAction') }}
        </v-card-title>
        <v-card-subtitle>
          {{ $t('removeOpeningHour') }}
        </v-card-subtitle>
        <v-card-text>
          <div class="pb-1">
            {{ $t('youSureYouWantToRemoveThisOpeningHour') }}
          </div>
          <div v-if="weekday">
            {{ weekday }}
          </div>
          <div v-if="value.starthours && value.endhours">
            {{ formatTime(value.starthours) }} - {{ formatTime(value.endhours) }}
          </div>
        </v-card-text>
        <v-card-actions>
          <v-row dense>
            <v-col cols="auto">
              <v-btn
                block
                @click="confirm = false"
                large
                color="error"
              >
                {{ $t('abort') }}
              </v-btn>
            </v-col>
            <v-spacer />
            <v-col cols="auto">
              <v-btn
                block
                large
                color="primary"
                @click="confirmRemove"
              >
                {{ $t('confirm') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import TimeSelection from "@/components/common/TimeSelection"

export default {
	name: "OpeningHour",
	components: {
		TimeSelection
	},
	props: {
		showCustomSlotOptions: Boolean,
		value: {
      type: Object,
      default: () => {}
    },
		weekday: {
      type: String,
      default: ''
    }
	},
	data() {
		return {
			timeIntervalsInMillis: [],
			confirm: false
		}
	},
	created() {
		let minutesInDay = []
		minutesInDay.push({value: null, text: '- ' + this.$t('noValue') + ' -'})

		for(let i = 0; i < 1440; i++) {
			let stringValue = ""
			let minutes = Math.floor(i % 60)
			let hours = Math.floor((i / 60) % 24)

			if(hours > 0){
				stringValue = hours + ' ' + this.$t('hoursAnd') + ' '
			}
			stringValue = stringValue + minutes + ' ' + this.$t('minutesLC')

			minutesInDay.push({value: i * 60000, text: stringValue})
		}
		this.timeIntervalsInMillis = minutesInDay
	},
	computed: {
		showCustomSlots() {
			return this.value.maximumSalesPerSlot || this.value.intervalBetweenSlotsInMillis || this.value.pauseBetweenSlotsInMillis || this.showCustomSlotOptions
		}
	},
	methods: {
		changeStartHours(value) {
			this.value.starthours = value
		},
		changeEndHours(value) {
			this.value.endhours = value
		},
		maxSalesToFloat() {
			this.value.maximumSalesPerSlot = this.value.maximumSalesPerSlot.replace(/[^\d.-]/g, '')
		},
		formatTime(time) {
			let t = time
			if(time.length === 1) {
				t = time.padStart(2, '0').padEnd(4, '0')
			} else if(time.length === 2) {
				t = time.padEnd(4, '0')
			}
			t = time.padStart(4, '0')
			return t.substr(0,2) + ':' + t.substr(2,2)
		},
		confirmRemove() {
			this.$emit('confirmRemove')
			this.confirm = false
		}
	}
}
</script>

<style scoped>

</style>
